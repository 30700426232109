
import { defineComponent } from "vue";
import MpxIcon from "@mekari/pixel/src/js/components/Icon/index.vue";
import { ROUTES, RouteType } from "@/constants/routes";
import { getFlagValue } from "@/utils/flagsmith";

interface Data {
  ROUTES: RouteType;
  collapsed: boolean;
  flags: {
    activityLogs: boolean;
    apiUsage: boolean;
  };
}

export default defineComponent({
  components: {
    MpxIcon,
  },
  data() {
    return {
      ROUTES: {
        HMAC_VALIDATOR: ROUTES.HMAC_VALIDATOR,
      },
      collapsed: false,
      flags: {
        activityLogs: false,
        apiUsage: false,
      },
    } as Data;
  },
  computed: {},
  mounted() {
    this.fetchFlags();
  },
  methods: {
    toggle(): void {
      if (this.collapsed) {
        this.collapsed = false;
      } else {
        this.collapsed = true;
      }
    },
    async fetchFlags() {
      try {
        this.flags.activityLogs = await getFlagValue("enable_activity_log");
        this.flags.apiUsage = await getFlagValue("enable_api_usage");
      } catch (error) {
        console.error("Error fetching flags:", error);
      }
    },
  },
});
