import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "navbar navbar-expand-lg navbar-light" }
const _hoisted_2 = { class: "navbar-nav account-nav" }
const _hoisted_3 = { class: "image" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "detail" }
const _hoisted_6 = { class: "fullname" }
const _hoisted_7 = { class: "email" }
const _hoisted_8 = {
  key: 1,
  class: "dropdown"
}
const _hoisted_9 = { class: "header" }
const _hoisted_10 = { class: "image" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "fullname" }
const _hoisted_13 = { class: "email" }
const _hoisted_14 = { class: "menu" }
const _hoisted_15 = {
  class: "menu-item",
  href: "/users/sign_out"
}
const _hoisted_16 = { class: "footer" }
const _hoisted_17 = { class: "links" }
const _hoisted_18 = { href: "https://mekari.com/kebijakan-privasi/" }
const _hoisted_19 = { href: "https://mekari.com/persyaratan-layanan/" }
const _hoisted_20 = { href: "https://mekari.com/tentang-mekari-account/" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdc_logo = _resolveComponent("mdc-logo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("nav", _hoisted_1, [
      _createVNode(_component_router_link, {
        to: "/dashboard",
        class: "navbar-brand mr-auto"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_mdc_logo, {
            height: "45",
            viewBox: "0 0 150 50"
          })
        ]),
        _: 1
      }),
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_ctx.$store.state.user.id != '')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              href: "#",
              class: _normalizeClass(["toggle", {
            active: _ctx.menuOpen,
          }]),
              role: "button",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleAccountMenu && _ctx.toggleAccountMenu(...args)))
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("img", {
                  src: _ctx.$store.state.user.avatar
                }, null, 8, _hoisted_4)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.fullName), 1),
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$store.state.user.email), 1)
              ])
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.menuOpen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("img", {
                    src: _ctx.$store.state.user.avatar
                  }, null, 8, _hoisted_11)
                ]),
                _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.fullName), 1),
                _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$store.state.user.email), 1)
              ]),
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "divider" }, null, -1)),
              _createElementVNode("div", _hoisted_14, [
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "divider" }, null, -1)),
                _createElementVNode("a", _hoisted_15, _toDisplayString(_ctx.$t("layout.navbar.sign_out")), 1)
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("a", _hoisted_18, _toDisplayString(_ctx.$t("layout.navbar.privacy")), 1),
                  _createElementVNode("a", _hoisted_19, _toDisplayString(_ctx.$t("layout.navbar.terms_of_use")), 1),
                  _createElementVNode("a", _hoisted_20, _toDisplayString(_ctx.$t("layout.navbar.about")), 1)
                ]),
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "copyright" }, "Mekari © 2021", -1))
              ])
            ]))
          : _createCommentVNode("", true)
      ])), [
        [_directive_click_outside, _ctx.closeAll]
      ])
    ])
  ]))
}